/* The actual timeline (the vertical ruler) */
.main-timeline-4 {
    position: relative;
}

/* The actual timeline (the vertical ruler) */
.main-timeline-4::after {
    content: "";
    position: absolute;
    width: 3px;
    background-color: #bbb;
    top: 0;
    bottom: 0;
    left: 50%;
    margin-left: -3px;
}

/* Container around content */
.timeline-4 {
    position: relative;
    background-color: inherit;
    width: 50%;
}

/* The circles on the timeline */
.timeline-4::after {
    content: "";
    position: absolute;
    width: 25px;
    height: 25px;
    right: -11px;
    background-color: #bbb;
    top: 15px;
    border-radius: 50%;
    z-index: 1;
}

/* Place the container to the left */
.left-4 {
    padding: 0px 40px 20px 0px;
    left: 0;
}

/* Place the container to the right */
.right-4 {
    padding: 0px 0px 20px 40px;
    left: 50%;
}

/* Add arrows to the left container (pointing right) */
.left-4::before {
    content: " ";
    position: absolute;
    top: 18px;
    z-index: 1;
    right: 30px;
    border: medium solid #d3d5e0;
    border-width: 10px 0 10px 10px;
    border-color: transparent transparent transparent #d3d5e0;
}

/* Add arrows to the right container (pointing left) */
.right-4::before {
    content: " ";
    position: absolute;
    top: 18px;
    z-index: 1;
    left: 30px;
    border: medium solid #0a60a1;
    border-width: 10px 10px 10px 0;
    border-color: transparent #0a60a1 transparent transparent;
}

/* Fix the circle for containers on the right side */
.right-4::after {
    left: -14px;
}

/* Media queries - Responsive timeline on screens less than 600px wide */
@media screen and (max-width: 600px) {

    /* Place the timelime to the left */
    .main-timeline-4::after {
        left: 31px;
    }

    /* Full-width containers */
    .timeline-4 {
        width: 100%;
        padding-left: 70px;
        padding-right: 25px;
    }

    /* Make sure that all arrows are pointing leftwards */
    .timeline-4::before {
        left: 60px;
        border: medium solid white;
        border-width: 10px 10px 10px 0;
        border-color: transparent white transparent transparent;
    }

    /* Make sure all circles are at the same spot */
    .left-4::after,
    .right-4::after {
        left: 18px;
    }

    .left-4::before {
        right: auto;
    }

    /* Make all right containers behave like the left ones */
    .right-4 {
        left: 0%;
    }
}

.gradient-custom {
    /* fallback for old browsers */
    background: #0a60a1;

    /* Chrome 10-25, Safari 5.1-6 */
    background: -webkit-linear-gradient(to right,
    #0a60a1,
    #eef0fc);

    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background: linear-gradient(to right,
    #0a60a1,
    #d3d5e0);
}

.gradient-custom-4 {
    /* fallback for old browsers */
    background: #0a60a1,;

    /* Chrome 10-25, Safari 5.1-6 */
    background: -webkit-linear-gradient(to right,
    #0a60a1,
    #eef0fc);

    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background: linear-gradient(to right,
    #0a60a1,
    #eef0fc);
}

.text-custom-color {
    color: white;
}

.iframe-border {
    background-color: #dedede;
    border-radius: 5px;
    display: flex;
}

.title-style {
    color: rgb(10, 96, 161);
    font-family: 'Poppins';
}